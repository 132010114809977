<template>
<div id="main-wrapper" class="skin-green">
  <b-overlay
    :show="loadingOverlay"
    rounded="sm"
    style="min-height: 100vh; display: flex; flex-direction: column;"
  >
    <bo-header />
    <bo-sidebar />
    <div class="page-wrapper">
    <router-view />
    </div>
    <bo-footer />
  </b-overlay>
</div>
</template>
<script>
import GlobalVue from '@/libs/Global'
import BoFooter from '@/components/backend/BoFooter.vue'
import BoHeader from '@/components/backend/BoHeader.vue'
import BoSidebar from '@/components/backend/BoSidebar.vue'

export default {
  extends:GlobalVue,
  components: {
    BoHeader,
    BoSidebar,
    BoFooter,
  }
}
</script>