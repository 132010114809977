import _ from 'lodash'

const menu = [
  {
    title: 'Dashboard',
    route: {name: 'BoDashboard'},
    menu: 'BoDashboard',
    icon:  'icon-speedometer',
  },
  {
    cap: 'Website Management',
    relate: [
      'BoHome',
      'BoLifeAtL9',
      'BoContactPage',
      'BoCareer',
      'BoCareerPage',
      // 'BoApplicants',
      'BoOurWorks',
      'BoOurWorksCategory',
      'BoJournalCategory',
      'BoJournal',
      'BoTermCondition',
      'BoPrivacyPolicy',
      'BoCookiePolicy',
      'BoWebSettings',
      'StaticSEO',
    ]
  },
  {
    title: 'Home',
    route: {name: 'BoHome'},
    icon:  'ti-home',
    menu: 'BoHome'
  },
  {
    title: 'Life At Lingkar9',
    route: {name: 'BoLifeAtL9'},
    icon:  'icon-grid',
    menu: 'BoLifeAtL9'
  },
  {
    title: 'Contact Us',
    icon:  'icon-paper-plane',
    children: [
      {
        title: 'Contact List',
        route: {name: 'BoContact'},
        menu: 'BoContact'
      },
      {
        title: 'Contact Page',
        route: {name: 'BoContactPage'},
        menu: 'BoContactPage'      
      },
    ]
  },
  {
    title: 'Career',
    icon:  'icon-briefcase',
    children: [
      {
        title: 'Career',
        route: { name: 'BoCareer' },
        menu: 'BoCareer'        
      },
      {
        title: 'Career Page',
        route: { name: 'BoCareerPage' },
        menu: 'BoCareerPage'        
      },
      // {
      //   title: 'Applicants',
      //   route: { name: 'BoApplicants' },
      //   menu: 'BoApplicants'
      // },
    ]
  },
  {
    title: 'Inquiry',
    icon:  'ti-clipboard',
    route: { name: "BoInquiry" },
    menu: "BoInquiry"
  },
  {
    title: 'Our Works',
    icon: 'ti-image',
    children: [
      {
        title: 'List',
        route: {name: 'BoOurWorks'},
        menu: 'BoOurWorks'
      },
      {
        title: 'Category',
        route: {name: 'BoOurWorksCategory'},
        menu: 'BoOurWorksCategory'
      },
    ]
  },
  {
    title: 'Journal',
    icon: 'ti-layout-list-thumb',
    children: [
      {
        title: 'Journal',
        route: {
          name: 'BoJournal',
        },
        menu: 'BoJournal',
      },
      {
        title: 'Journal Category',
        route: {
          name: 'BoJournalCategory',
        },
        menu: 'BoJournalCategory',
      },
    ]
  },
  {
    title: 'Legal',
    icon: 'icon-docs',
    children: [
      {
        title: 'Terms and Conditions',
        route: {
          name: 'BoTermCondition',
        },
        menu: 'BoTermCondition',
      },
      {
        title: 'Privacy Policy',
        route: {
          name: 'BoPrivacyPolicy',
        },
        menu: 'BoPrivacyPolicy',
      },
      {
        title: 'Cookie Policy',
        route: {
          name: 'BoCookiePolicy',
        },
        menu: 'BoCookiePolicy',
      },
    ]
  },
  {
    cap: 'CONTENT MANAGEMENT',
    relate: [
      'BoClients',
      'BoTestimonials',
      'BoServices',
      'BoIndustries'
    ]
  },
  {
    title: 'Services',
    route: {name: 'BoServices'},
    icon:  'ti-bookmark-alt',
    menu: 'BoServices'
  },
  {
    title: 'Industries',
    route: {name: 'BoIndustries'},
    icon:  'ti-briefcase',
    menu: 'BoIndustries'
  },
  {
    title: 'Clients',
    route: {name: 'BoClients'},
    icon:  'ti-id-badge',
    menu: 'BoClients'
  },
  {
    title: 'Testimonials',
    route: {name: 'BoTestimonials'},
    icon:  'icon-speech',
    menu: 'BoTestimonials'
  },
  {
    cap: 'CMS Settings',
    relate: [
      'Users',
      'BoUserLevel'
    ]
  },
  {
    title: 'User Management',
    icon: 'icon-user-follow',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'BoUserLevel'},
        menu: 'BoUserLevel'
      },
    ],
  },
  // {
  //   title: 'AuditTrail',
  //   route: {
  //     name: 'AuditTrail'
  //   },
  //   icon: 'icon-check',
  //   menu: 'AuditTrail'
  // },
]

let nav = menu

const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      })
    }

    return v
  })
}

export default nav